import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBoolean, useUnmount } from 'react-use';

// import { USER_ID } from 'lib/apiClient';
import { audioToBase64, createURLfromChunks } from 'lib/mediaRecorder';
import { getStoriesAndLessonsStatus } from 'lib/utils';

import { TextAndAudioEntity } from 'types';

import useGetAssignmentInfo from 'api/useGetAssinmentInfoGoLive';
import useGetNextStory from 'api/useGetNextStoryGoLive';
// import useStoryComplete from 'api/useStoryComplete';
import useStoryCompleteGoLive from 'api/useStoryCompleteGoLive';
import useStoryCompleteLMS from 'api/useStoryCompleteLMS';

import useRecordingStore from 'screens/Story/useRecordingStore';

import Button from 'components/ui/Button';
import ContentLuca from 'components/ui/ContentLuca';

import { URLS } from 'components/App';
import Feed from 'components/Feed';

import { THUMB_DOWN_ICON, THUMB_UP_ICON } from 'assets/icons';

import * as Styled from './styles';
import { useSequenceAudio } from './useSequenceAudio';
import { queryClient } from 'lib/queryClient';

const HearMySelf = ({ source }: { source: string[] }) => {
  // TODO: We are not able to get a progress in the future with this approach. Consider creating a BE endpoint that generates preview for us (Serverless function?)
  const sequenceAudio = useSequenceAudio(source);

  useUnmount(() => sequenceAudio.stop());

  return (
    <Styled.StoryFinishRecordingControl
      onClick={async () => await sequenceAudio.toggle()}
      status={sequenceAudio.playbackStatus}
      text="Hear myself"
    />
  );
};

const StoryFinish = () => {
  const navigate = useNavigate();
  const [isFeedFull, setIsFeedFull] = useBoolean(false);
  const { recordings, reset: clearRecordings, storyId } = useRecordingStore();
  const { data: assignmentResponse } = useGetAssignmentInfo();
  const { data: storyData } = useGetNextStory();

  // const sendStoryResultsMutation = useStoryComplete();
  const storyCompleteLms = useStoryCompleteLMS();
  const storyCompleteGoLive = useStoryCompleteGoLive();

  const hasRecordings = useMemo(() => Object.keys(recordings).length > 0, [recordings]);

  const audioSrcSet = useMemo(
    () =>
      Object.values(recordings ?? {}).map(
        (rec) => createURLfromChunks(rec?.chunks ?? [], { type: rec?.chunks?.[0]?.type ?? 'audio/wav' })[0]
      ),
    [recordings]
  );

  const PHRASES = useMemo(() => {
    if (assignmentResponse) {
      const { isAllLessonComplete, isAllStoriesComplete } = getStoriesAndLessonsStatus(assignmentResponse);
      if (isAllStoriesComplete && !isAllLessonComplete) {
        return [
          {
            id: 0,
            text: 'Congratulations! You finished the story!',
            audio: 'story_instruction/s2.mp3',
          },
          {
            id: 1,
            text: 'Now let’s learn a lesson.',
            audio: 'story_instruction/s3.mp3',
          },
          {
            id: 2,
            text: 'Did you like the story?',
            audio: '',
          },
        ];
      } else {
        return [
          {
            id: 0,
            text: 'Congratulations! You finished the story!',
            audio: 'story_instruction/s2.mp3',
          },
          {
            id: 2,
            text: 'Did you like the story?',
            audio: '',
          },
        ];
      }
    }
  }, [assignmentResponse]);

  const goToMenu = async () => {
    const textsAndAudiosPromises = Object.values(recordings).map<Promise<TextAndAudioEntity>>(
      async ({ chunks, text, id }) => {
        const [, wavBlob] = createURLfromChunks(chunks, { type: 'audio/wav' }); // Manually convert to ".wav" as BE requests
        const audio = await audioToBase64(wavBlob);
        const sentence_id = String(id);
        return { audio, text, sentence_id };
      }
    );

    const textsAndAudios = await Promise.all(textsAndAudiosPromises);
    // const payload = { userId: USER_ID, storyId, textsAndAudios };
    const combineStoryText = storyData?.sentences.map((sentence) => sentence.text).join(' ');
    // sendStoryResultsMutation.mutate(payload);

    await storyCompleteLms
      .mutateAsync({ story_id: storyId, title: storyData?.title ?? 'Untitled', story_text: combineStoryText ?? '' })
      .then(() => {
        storyCompleteGoLive
          .mutateAsync({
            audioList: textsAndAudios.map((item) => ({
              sentenceId: String(item.sentence_id),
              audioStr: item.audio.split(',')[1],
            })),
          })
          .then(() => {
            queryClient.invalidateQueries({queryKey: ['assignmentInfo']})
            navigate(URLS.menu);
            clearRecordings();
          });
      });
  };

  const handleFeedFull = () => setIsFeedFull(true);

  return (
    <ContentLuca
      forwardDisabled={storyCompleteGoLive.isLoading || storyCompleteGoLive.isLoading}
      onForward={isFeedFull && hasRecordings && !!storyId ? goToMenu : undefined}
    >
      <Feed phrases={PHRASES ?? []} onFeedFull={handleFeedFull}>
        <Styled.LessonPull>
          <Button iconLeft={THUMB_UP_ICON}>Yes</Button>
          <Button iconLeft={THUMB_DOWN_ICON}>No</Button>
        </Styled.LessonPull>
        {audioSrcSet.length > 0 && <HearMySelf source={audioSrcSet} />}
      </Feed>
    </ContentLuca>
  );
};

export default StoryFinish;
